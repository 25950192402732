import { ref } from '@vue/composition-api';
import { useLazyQuery } from '@vue/apollo-composable';
import schema from '@/modules/account/schema.graphql';
import {
  fetchAccessRights,
  updateAccessRights
} from '@/modules/access/api/access';
import { objectService } from '@/modules/objects/api';

export function useAccessRights() {
  const loading = ref(false);
  const rightsEditor = ref([]);
  const rightsUser = ref([]);
  const rightsReader = ref([]);

  const { load, onResult, result } = useLazyQuery(
    schema.loadGroups,
    {},
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );

  onResult(() => {
    rightsEditor.value = result.value.userGroups.map(item => ({
      ...item,
      disabled: item.groupName === 'Nobody'
    }));
    rightsUser.value = result.value.userGroups;
    rightsReader.value = result.value.userGroups;
  });

  const save = async (id, accessRights) => {
    try {
      loading.value = true;
      await updateAccessRights(id, accessRights);
      loading.value = false;
    } catch (e) {
      console.log(e);
    }
  };

  const saveMany = async (ids, accessRights) => {
    try {
      loading.value = true;
      await objectService.massiveUpdate(
        ids.map(id => ({ id, ...accessRights }))
      );
    } catch (e) {
      console.log(e);
    } finally {
      loading.value = false;
    }
  };

  const fetchAccessRightsForObject = async id => {
    return fetchAccessRights(id);
  };

  return {
    loading,
    rightsEditor,
    rightsUser,
    rightsReader,
    fetchAccessRightsForObject,
    load,
    save,
    saveMany
  };
}
