import { objectService } from '@/modules/common/api';
import { apiClient } from '@/provider';
import objectGroupScheme from './object-group.graphql';

export default {
  ...objectService,
  fetchObjectGroupSchemeId() {
    return apiClient
      .query({
        query: objectGroupScheme.fetchObjectGroupSchemaId
      })
      .then(r => r.data.schemata[0]?.id || '');
  },
  fetchObjectGroup(id) {
    return apiClient
      .query({
        query: objectGroupScheme.fetchObjectGroup,
        variables: {
          id
        }
      })
      .then(r => r.data.object);
  },
  update({ id, name, description = '' }) {
    return apiClient.mutate({
      mutation: objectGroupScheme.updateObjectGroup,
      variables: {
        id,
        name,
        description
      }
    });
  }
};
