import { objectService } from '@/modules/common/api';
import { apiClient } from '@/provider';
import { prepareObject } from '@/provider/utils';
import objectScheme from './object.graphql';
export default {
  ...objectService,
  async fetch(id) {
    const res = await apiClient.query({
      query: objectScheme.fetch,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    return prepareObject(res.data.object);
  },

  update(id, { name, description, enabled }) {
    return apiClient.mutate({
      mutation: objectScheme.update,
      variables: {
        id,
        name,
        description,
        enabled
      }
    });
  },

  setFavourite(id, favourite) {
    return apiClient.mutate({
      mutation: objectScheme.setFavourite,
      variables: {
        id,
        favourite
      }
    });
  }

  // delete(id) {
  //   return apiClient.mutate({
  //     mutation: objectScheme.delete,
  //     variables: {
  //       id
  //     }
  //   });
  // },

  // create({ name, description, enabled, schemaId }) {
  //   return apiClient.mutate({
  //     mutation: objectScheme.create,
  //     variables: {
  //       input: {
  //         object: {
  //           name,
  //           description,
  //           enabled,
  //           schemaId
  //         }
  //       }
  //     }
  //   });
  // }
};
