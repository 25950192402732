import { apiClient } from '@/provider';
import statesScheme from './object-states-list.graphql';

export default {
  fetch(objectId) {
    return apiClient
      .query({
        query: statesScheme.load,
        variables: {
          objectId
        },
        fetchPolicy: 'no-cache'
      })
      .then(r => r.data?.objectsToObjects?.map(i => i.object2));
  }
};
