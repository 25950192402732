import { apiClient } from '@/provider';

import accessScheme from './access.graphql';

import { objectService } from '@/modules/common/api';

type AccessRights = {
  readergroup: string;
  usergroup: string;
  editorgroup: string;
};

const fetchAccessRights = async (id: string): Promise<AccessRights> => {
  const { readergroup, usergroup, editorgroup } = (
    await apiClient.query({
      query: accessScheme.fetchObjectAccess,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    })
  ).data.object;
  return { readergroup, usergroup, editorgroup };
};
const updateAccessRights = async (
  id: string,
  patch: AccessRights
): Promise<void> => {
  return objectService.update(id, patch);
};

export { fetchAccessRights, updateAccessRights };
