<template>
  <div>
    <v-select
      :value="value.editorgroup"
      data-test-rights="editors"
      item-value="id"
      item-text="groupName"
      label="Editors group"
      :items="rightsEditor"
      @input="onChange({ editorgroup: $event })"
    >
      <template v-slot:item="item">
        <div :data-test-edit-rights="item.item.name">
          {{ item.item.groupName }}
        </div>
      </template>
    </v-select>
    <v-select
      :value="value.usergroup"
      data-test-rights="users"
      label="Users group"
      item-value="id"
      item-text="groupName"
      :items="rightsUser"
      @input="onChange({ usergroup: $event })"
    >
      <template v-slot:item="item">
        <div :data-test-user-rights="item.item.name">
          {{ item.item.groupName }}
        </div>
      </template>
    </v-select>
    <v-select
      :value="value.readergroup"
      data-test-rights="reader"
      label="Reader group"
      item-value="id"
      item-text="groupName"
      :items="rightsReader"
      @input="onChange({ readergroup: $event })"
    >
      <template v-slot:item="item">
        <div>
          {{ item.item.groupName }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'EditRightsGroupFields',
  props: {
    rightsEditor: {
      type: Array,
      default: () => []
    },
    rightsUser: {
      type: Array,
      default: () => []
    },
    rightsReader: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({
        editorgroup: '',
        usergroup: '',
        readergroup: ''
      })
    }
  },
  setup(props, { emit }) {
    const onChange = data => {
      emit('input', {
        ...props.value,
        ...data
      });
    };

    return {
      onChange
    };
  }
};
</script>
