import { apiClient } from '@/provider';
import geoScheme from './object-geo-list.graphql';

export default {
  fetchGezones(objectId) {
    return apiClient
      .query({
        query: geoScheme.load,
        variables: {
          objectId,
          geoTags: ['application', 'monitor', 'geozone']
        },
        fetchPolicy: 'no-cache'
      })
      .then(r =>
        r.data?.objectsToObjects?.map(i => ({
          relationId: i.id,
          ...i.object2
        }))
      );
  },
  fetchLandmarks(objectId) {
    return apiClient
      .query({
        query: geoScheme.load,
        variables: {
          objectId,
          geoTags: ['application', 'monitor', 'landmark']
        },
        fetchPolicy: 'no-cache'
      })
      .then(r =>
        r.data?.objectsToObjects?.map(i => ({
          relationId: i.id,
          ...i.object2
        }))
      );
  },
  fetchGeotags(objectId) {
    return apiClient
      .query({
        query: geoScheme.load,
        variables: {
          objectId,
          geoTags: ['application', 'monitor', 'geotag']
        },
        fetchPolicy: 'no-cache'
      })
      .then(r =>
        r.data?.objectsToObjects?.map(i => ({
          relationId: i.id,
          ...i.object2
        }))
      );
  },
  async fetchGeoObjects(objectId) {
    const res = await Promise.all([
      this.fetchGezones(objectId),
      this.fetchLandmarks(objectId)
    ]);
    return res.reduce((acc, cur) => [...acc, ...cur], []);
  }
};
